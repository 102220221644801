import axios from "axios";
import { GET_ORDERS } from "./types";

// GET ORDERS
export const getOrders = () => (dispatch) => {
  axios
    .get("https://jsonplaceholder.typicode.com/todos")
    .then((res) => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
