import palette from "../palette";

const MuiButton = {
  contained: {
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
    backgroundColor: palette.primary.dark,
    color: palette.white,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: palette.primary.dark,
      color: palette.white,
      fontWeight: 700,
      border: "1px solid black",
    },
  },
};

export default MuiButton;
