import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
  },
}));

function KpiCard({ title, kpi }) {
  const classes = useStyles();

  return (
    <Card>
      <CardContent>
        {" "}
        <Typography
          variant="h6"
          color="textSecondary"
          gutterBottom
          className={classes.title}
        >
          {title}
        </Typography>
        <Typography variant="h3">{kpi}</Typography>
      </CardContent>
    </Card>
  );
}

export default KpiCard;
