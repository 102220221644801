import { Grid } from "@material-ui/core";
import React from "react";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";

function Login() {
  return (
    <Grid container>
      <LeftContent />
      <RightContent />
    </Grid>
  );
}

export default Login;
