import React, { Fragment, useEffect } from "react";
import { withAlert, useAlert } from "react-alert";
import { useSelector } from "react-redux";

function Alerts() {
  const alert = useAlert();
  const error = useSelector((state) => state.errorReducer);

  useEffect(() => {
    if (error.msg.non_field_errors)
      alert.error(error.msg.non_field_errors.join());
  });

  return <Fragment />;
}

export default withAlert()(Alerts);
