import React from "react";
import { Hidden, makeStyles } from "@material-ui/core";
import { Divider, Drawer } from "@material-ui/core";
import Profile from "./Profile.js";
import SidebarNav from "./SidebarNav.js";
import { useDispatch, useSelector } from "react-redux";
import { changeSidebarVisibility } from "../../../actions/app.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 24,
  },
  drawer: {
    width: 240,
    height: 500,
  },
  divider: {
    margin: theme.spacing(4, 0, 1),
  },
  drawerPaper: {
    width: 240,
    marginTop: 64,
  },
}));

/* https://www.youtube.com/watch?v=Ix1LZGBSp-E&list=PL4cUxeGkcC9gjxLvV4VEkZ6H6H4yWuS58&index=13 */
function Sidebar() {
  const classes = useStyles();
  const visibility = useSelector((state) => state.appReducer.sideBarVisible);
  const dispatch = useDispatch();

  const handleSidebarOpen = () => {
    console.log("Sidebar - Hamburger clicked");
    dispatch(changeSidebarVisibility(!visibility));
  };

  const drawer = (
    <div className={classes.root}>
      <Profile />
      <Divider className={classes.divider} />
      <SidebarNav />
    </div>
  );

  // Functions controlling the opening/closing of hambuger in topbar

  return (
    <nav>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={visibility}
          onClose={handleSidebarOpen}
          //onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
          className={classes.drawer}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default Sidebar;
