import React, { useEffect } from "react";
import "./App.css";
import Layout from "./components/layouts/Layout.js";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Coupons from "./pages/Coupons";
import PrivateRoute from "./components/authorization/PrivateRoute";

//Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";

// Alerts
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Alerts from "./components/messages/Alerts";
import Login from "./components/authorization/Login";

const alertOptions = {
  timeout: 3000,
  position: "top center",
};

function App() {
  // is fired when app loads
  useEffect(() => {
    store.dispatch(loadUser());
  });

  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Alerts />
        <ThemeProvider theme={theme}>
          <Router>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <PrivateRoute exact path="/">
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path="/dashboard">
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path="/customers">
                <Layout>
                  <Customers />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path="/orders">
                <Layout>
                  <Orders />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path="/coupons">
                <Layout>
                  <Coupons />
                </Layout>
              </PrivateRoute>
              <Route path="/login">
                <Login />
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </AlertProvider>
    </Provider>
  );
}

export default App;
