import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../actions/auth.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";

function Copyright() {
  return (
    <Typography variant="body2" color="textPrimary" align="center">
      <Link color="inherit" href="https://diaduct.com/kontakt/">
        Dont have an account? Contact us
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  rightContent: {
    background: theme.palette.white,
    height: "100vh",
  },
  formbox: {
    width: "307px",
    height: "356px",
  },
  form: {
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(0, 0, 4),
    top: theme.spacing(2),
    background: "#0575E6",
    borderRadius: "30px",
    fontSize: 16,
    fontWeight: 500,
  },
  forgotPW: {
    color: theme.palette.secondary.main,
  },
}));

function RightContent() {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const { username, password } = inputs;
  const dispatch = useDispatch();
  const UserisAuthenticated = useSelector(
    (state) => state.authReducer.isAuthenticated
  );

  // Read values from input fields and set if to state of component
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (username && password) {
      dispatch(loginUser(username, password));
    }
  }

  if (UserisAuthenticated) {
    return <Redirect to="/" />;
  }
  return (
    <Grid
      container
      item
      xs={12}
      sm={9}
      md={5}
      justifyContent="center"
      alignItems="center"
      className={classes.rightContent}
    >
      <Box className={classes.formbox}>
        <Box>
          <Typography variant="h2">Hey, good to see you again!</Typography>
          <Typography variant="h4">Welcome Back 😉</Typography>
        </Box>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            className={classes.radius}
            variant="outlined"
            id="username"
            placeholder="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
              style: { fontSize: 20, fontWeight: 500, borderRadius: "32px" },
            }}
          />
          <TextField
            variant="outlined"
            placeholder="Password"
            margin="normal"
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              style: { fontSize: 20, fontWeight: 500, borderRadius: "32px" },
            }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="dark" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Sign In
          </Button>
          {/* }
          <Grid container>
            <Grid item xs>
              <Link
                href="https://diaduct.com/kontakt/"
                variant="body2"
                className={classes.forgotPW}
              >
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          */}
        </form>
        <Box m={2}>
          <Copyright />
        </Box>
      </Box>
    </Grid>
  );
}

export default RightContent;
