import React from "react";
import { Avatar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  size: {
    width: 150,
    height: 100,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

function Profile() {
  const classes = useStyles();
  const username = useSelector((state) => state.authReducer.user.username);
  const email = useSelector((state) => state.authReducer.user.email);

  const defaultUser = {
    //name: "Max Mustermann",
    avatar: "/logos/Diaduct_Website_Logo150x100.png",
    //bio: "Webshop Analytics",
  };

  return (
    <div className={classes.root}>
      <Avatar
        alt="Avatar Person"
        src={defaultUser.avatar}
        className={classes.size}
        variant="square"
      >
        DC
      </Avatar>
      <Typography variant="h4">{username}</Typography>
      <Typography variant="body2">{email}</Typography>
    </div>
  );
}

export default Profile;
