import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PeopleIcon from "@material-ui/icons/People";
import RedeemIcon from "@material-ui/icons/Redeem";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  activeItem: {
    backgroundColor: theme.palette.primary.light,
  },
  primary: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const sidebarItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon color="secondary" />,
    path: "/dashboard",
  },
  {
    text: "Customers",
    icon: <PeopleIcon color="secondary" />,
    path: "/customers",
  },
  {
    text: "Orders",
    icon: <AssignmentIcon color="secondary" />,
    path: "/orders",
  },
  {
    text: "Coupons",
    icon: <RedeemIcon color="secondary" />,
    path: "/coupons",
  },
];

function SidebarNav() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  return (
    <List>
      {sidebarItems.map((item) => {
        const isActiveItem = location.pathname === item.path;
        return (
          <ListItem
            key={item.text}
            button
            onClick={() => history.push(item.path)}
            className={isActiveItem ? classes.activeItem : null}
            disableGutters
            divider
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.text}
              classes={isActiveItem ? { primary: classes.primary } : null}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default SidebarNav;
