import { SET_VISIBILITY } from "../actions/types";

const initialState = {
  sideBarVisible: false,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VISIBILITY:
      return { sideBarVisible: action.visibility };
    default:
      return initialState;
  }
}
