import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  leftContent: {
    background: theme.palette.blueGradient,
    height: "100vh",
    overflow: "hidden",
  },
  rightContent: {},
  leftBox: {
    height: "147px",
  },
  button: {
    top: "23px",
    background: "#0575E6",
    borderRadius: "30px",
    margin: "23px 0px",
  },
  h1: {
    color: theme.palette.white,
  },
  h4: {
    color: theme.palette.white,
  },
  readmore: {
    color: theme.palette.white,
  },
  elipse1: {
    position: "fixed",
    width: "557px",
    height: "90vh",
    left: "15vw",
    top: "-70vh",
    border: "1px solid #0575E6",
    borderRadius: "50%",
  },
  elipse2: {
    position: "fixed",
    width: "557px",
    borderRadius: "50%",
    border: "2px solid #0575E6",
    height: "90vh",
    left: "5vw",
    bottom: "-70%",
    zIndex: 1,
  },
}));

function LeftContent() {
  const classes = useStyles();

  return (
    <Hidden xsDown>
      <Grid
        item
        container
        sm={3}
        md={7}
        className={classes.leftContent}
        justifyContent="center"
        alignItems="center"
      >
        <Hidden smDown>
          <Box
            sx={{ width: "100%", maxWidth: 500 }}
            className={classes.leftBox}
          >
            <Typography variant="h1" component="div" className={classes.h1}>
              Diaduct Cloud
            </Typography>
            <Typography variant="h4" component="div" className={classes.h4}>
              Data-driven Marketing for SAP Commerce Cloud
            </Typography>
            <Button variant="contained" className={classes.button}>
              <Typography variant="button" className={classes.readmore}>
                <Link
                  href="https://diaduct.com"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Read More
                </Link>
              </Typography>
            </Button>
          </Box>
          <Box className={classes.elipse1}></Box>
          <Box className={classes.elipse2}></Box>
        </Hidden>
      </Grid>
    </Hidden>
  );
}

export default LeftContent;
