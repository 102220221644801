import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#333333";
const grey2 = "#43425D";
const blueGradient =
  "linear-gradient(180deg, #0531E6 0%, #02298A 84.79%, #021B79 100%)";

const palette = {
  black,
  white,
  grey2,
  blueGradient,
  primary: {
    contrastText: white,
    dark: "#181F34",
    main: "#1062E5",
    normal: "#226CE0",
    action: "#EEEDFF",
    light: "#F3F3FB",
  },
  secondary: {
    contrastText: white,
    dark: "#F3F3FB",
    main: "#FF6565",
    light: "#FFA2BE",
    lighten: "#FFE0E9",
  },
  success: {
    contrastText: white,
    dark: "#51D084",
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: "#E56860",
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: black,
    link: "#D5D5E2",
    black: black,
  },
  background: {
    default: "#DEE5E5",
    gray: "#F8F8FC",
    paper: white,
  },
  icon: "#A1A1B5",
  divider: colors.grey[200],
  neutral: "#F3F3FB",
};

export default palette;
