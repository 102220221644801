import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import KpiCard from "../components/basic/KpiCard";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  item: {
    minHeight: "50vh",
  },
  gridcontainer: {
    marginBottom: theme.spacing(2),
  },
}));

// DUMMY DATA 1
const cardData = [
  {
    id: 1,
    title: "ORDERS",
    kpi: 25000,
    difference: "Orders",
    duration: 1.25,
  },
  {
    id: 2,
    title: "TOTAL USERS",
    kpi: 700,
    difference: "Users",
    duration: 1.5,
  },
  {
    id: 3,
    title: "COMPLETED ORDERS",
    kpi: 14500,
    progress: true,
    duration: 1.75,
  },
  {
    id: 4,
    title: "TOTAL PROFIT",
    kpi: 125000,
    duration: 2.25,
  },
];

// DUMMY DATA 2
const chartData = [
  {
    id: 5,
    title: "SALES TREND",
  },
  {
    id: 6,
    title: "COUPON DISTRIBUTION",
  },
];

function Dashboard() {
  const classes = useStyles();
  return (
    <Container className={classes.container} maxWidth="xl">
      <Typography variant="h3" color="black" paragraph="True">
        Sales Activity for the current month
      </Typography>
      <Grid container spacing={2} className={classes.gridcontainer}>
        {cardData.map((card) => (
          <Grid item key={card.id} xs={12} sm={12} md={6} lg={3}>
            <KpiCard title={card.title} kpi={card.kpi}></KpiCard>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        {chartData.map((chart) => (
          <Grid item key={chart.id} xs={12} lg={6} className={classes.item}>
            <KpiCard title={chart.title}></KpiCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Dashboard;
