import axios from "axios";
import {
  AUTH_ERROR,
  USER_LOADED,
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  GET_ERRORS,
} from "./types";

// TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  // Checking if user is logged in (Token existent?)
  // TODO : Fix XSS attack risk
  axios
    .get(`${process.env.REACT_APP_API_URL}/auth/user`, tokenConfig(getState))
    .then((res) => {
      // if user logged in, res data contains {id, username and email}
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((error) => {
      // If no token, status 401 Unauthorized
      //TODO: error contains no response object?
      if (error.response) {
      }
      //TODO: impement Message Reducer dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// LOGIN USER
export const loginUser = (username, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ username, password });

  axios
    .post(`${process.env.REACT_APP_API_URL}/auth/login`, body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      const errors = {
        msg: err.response.data,
        status: err.response.status,
      };
      dispatch({ type: GET_ERRORS, payload: errors });
      dispatch({ type: LOGIN_FAIL });
    });
};

// LOGOUT USER
export const logoutUser = () => (dispatch, getState) => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/auth/logout`,
      null,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      if (err.response) {
        console.log("Error during Logout " + err.response.status);
        console.log(err.response.data);
      }
    });
};

// Helper Function: Setup config with token
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().authReducer.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};
