import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

function Coupons() {
  const classes = useStyles();
  return <div className={classes.root}>Coupons Page</div>;
}

export default Coupons;
