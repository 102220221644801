import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = (props) => {
  const auth = useSelector((state) => state.authReducer);
  const [children, setChildren] = useState([props.children]);

  useEffect(() => {
    setChildren(props.children);
  }, [props.children]);

  return (
    <Route
      render={(props) => {
        if (auth.isLoading) {
          return <h2>Loading...</h2>;
        } else if (!auth.isAuthenticated) {
          return <Redirect to="/login" />;
        }
        return children;
      }}
    />
  );
};

export default PrivateRoute;
