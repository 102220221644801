const MuiCard = {
  root: {
    boxShadow: "none",
    borderRadius: 8,
    height: "100%",
    padding: "8px 8px 0 8px",
  },
};

export default MuiCard;
