import { combineReducers } from "redux";
import orderReducer from "./orderReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import appReducer from "./appReducer";

export default combineReducers({
  orderReducer,
  authReducer,
  errorReducer,
  appReducer,
});
