import React from "react";
import Topbar from "./Topbar/Topbar.js";
import Sidebar from "./Sidebar/Sidebar.js";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  page: {
    backgroundColor: theme.palette.primary.light,
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 64px)",
  },
}));

function Layout({ children }) {
  const classes = useStyles();

  return (
    <div>
      <Topbar />
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.page}>{children}</div>
      </div>
    </div>
  );
}

export default Layout;
