import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
// TODO - Implement PropTypes as soon as Order API exists [24:14 3/7 Redux & HTTP]
//import { PropTypes } from "prop-types";
import { getOrders } from "../actions/orders.js";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

function Orders() {
  const classes = useStyles();
  const orders = useSelector((state) => state.orderReducer.orders);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Typography variant="h2">Orders Page</Typography>
      <Typography variant="body1">Orders:</Typography>
      {orders.map((order) => (
        <ul key={order.id}>
          <li>{order.userId}</li>
          <li>{order.id}</li>
          <li>{order.title}</li>
          <li>{order.completed}</li>
        </ul>
      ))}
    </div>
  );
}

export default Orders;
