import { GET_ORDERS } from "../actions/types.js";

const initialState = {
  orders: [
    {
      userId: 2,
      id: 1,
      title: "delectus aut autem",
      completed: false,
    },
  ],
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    default:
      return state;
  }
}
