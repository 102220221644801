import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

function Customers() {
  const classes = useStyles();
  return <div className={classes.root}>Customer Page</div>;
}

export default Customers;
